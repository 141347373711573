<template>
  <v-data-table
    show-expand
    must-sort
    fixed-header
    :loading="loading"
    :headers="headers"
    :items="drugs"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 20, 50, 100 ],
      itemsPerPageText: `${ $options.filters.capitalize($tc('drug', 2)) } ${ $t('per page') }:`,
      showFirstLastPage: true
    }"
    :items-per-page="20"
    class="elevation-2"
  >
    <template #top>
      <v-row
        justify="space-between"
        class="pt-3 px-3 mb-n3 "
      >
        <v-col md="3">
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer />
        <v-col md="2">
          <table-filter
            :value.sync="options.filter.delivery_methods"
            :choices="$delivery_methods"
            label="Routes"
            icon="fal fa-syringe"
            multiple
          />
        </v-col>
        <v-col md="2">
          <table-filter
            :value.sync="options.filter.resistance"
            :choices="resistances"
            label="Resistance"
            icon="fal fa-shield-virus"
            item-value="name.en"
            item-text="name.en"
            multiple
          />
        </v-col>
        <v-col md="2">
          <table-filter
            :value.sync="options.filter.class"
            :choices="classes"
            label="Class"
            icon="fal fa-sitemap"
            item-value="name"
            item-text="name"
            multiple
          />
        </v-col>
        <v-col md="2">
          <table-filter
            :value.sync="options.filter.type"
            :choices="types"
            label="Type"
            icon="fal fa-bacteria"
            item-value="name"
            item-text="name"
            multiple
          />
        </v-col>
        <v-col class="shrink">
          <v-btn
            v-if="$auth.check({ drugs: 'edit' })"
            color="success"
            fab
            small
            xv-on="on"
            class="mt-n5"
            xelevation="4"
            @click="$emit('toggle-right-drawer', {}, true)"
          >
            <v-icon small>
              fal fa-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template #expanded-item="{ item }">
      <td :colspan="headers.length">
        <v-row>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Requires renal dosing</v-list-item-title>
                <v-list-item-subtitle>{{ item.renal_dosing }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Requires hepatic dosing</v-list-item-title>
                <v-list-item-subtitle>{{ item.hepatic_dosing }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Common Side Effects</v-list-item-title>
                <v-list-item-subtitle>{{ item.common_side_effects }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Drug Interactions</v-list-item-title>
                <v-list-item-subtitle>{{ item.drug_interactions }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </td>
    </template>
    <template #item.name="{ item }">
      {{ item.name.en | truncate(26) }}
    </template>
    <template #item.brand_names="{ item }">
      {{ item.brand_names | truncate }}
    </template>
    <template #item.type="{ item }">
      <v-chip
        v-if="item.type"
        small
        label
        :color="stringColor(item.type)"
      >
        <v-icon
          small
          left
        >
          {{ types.find(x => x.name === item.type).icon }}
        </v-icon>
        {{ item.type }}
      </v-chip>
    </template>
    <template #item.class="{ item }">
      {{ item.class ? item.class.name : null }}
    </template>
    <template #item.delivery_methods="{ item }">
      <route-icon
        v-for="method in item.delivery_methods"
        :key="method"
        :route="method"
        small
        class="mr-1"
      />
    </template>
    <template #item.resistance="{ item }">
      <resistance-chip
        v-for="(resistance, index) in orderBy(item.resistance, 'name').slice(0, 1)"
        :key="index"
        :resistance="resistance"
        short-name
        x-small
        class="px-2 mr-1 mb-1"
      />
      <v-chip
        v-if="item.resistance.length > 1"
        x-small
        class="px-2 mb-1"
      >
        + {{ item.resistance.length - 1 }}
      </v-chip>
    </template>
    <template #item.score="{ item }">
      <arkscore-gauge
        :key="'arkscore-gauge-' + item.score_adverse_reaction"
        :value="item.score_adverse_reaction"
        :size="36"
      />
    </template>
    <template #item.actions="{ item }">
      <v-btn
        small
        ripple
        icon
        plain
        @click="$emit('toggle-right-drawer', item, true)"
      >
        <v-icon>fal fa-ellipsis-v</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
  import Vue2Filters from 'vue2-filters'

  export default {
    components: {
      SearchBar: () => import('@/components/tables/filters/SearchBar.vue'),
      TableFilter: () => import('@/components/tables/filters/TableFilter.vue'),
      ArkscoreGauge: () => import('@/components/arkscore/ArkscoreGauge.vue'),
      ResistanceChip: () => import('@/components/ResistanceChip.vue'),
      RouteIcon: () => import('@/components/drug/RouteIcon.vue'),
    },
    mixins: [
      Vue2Filters.mixin,
    ],
    props: {
      search: {
        type: String,
        default: null,
      },
    },
    metaInfo: {
      title: 'Drugs',
    },
    data () {
      return {
        loading: false,
        options: {
          search: null,
          filter: this.$route.query,
        },
        totalItems: null,
        headers: [
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Abbr.',
            value: 'abbreviation',
          },
          {
            text: 'Brand Names',
            value: 'brand_names',
          },
          {
            text: 'Type',
            value: 'type',
          },
          // {
          //   text: 'Class',
          //   value: 'class',
          // },
          {
            text: 'Routes',
            value: 'delivery_methods',
          },
          {
            text: 'Resistance',
            value: 'resistance',
            sortable: false,
          },
          {
            text: 'Adverse Reaction',
            value: 'score',
            align: 'center',
            sortable: false,
          },
          {
            sortable: false,
            align: 'end',
            value: 'actions',
          },
        ],
        types: [
          { name: 'Antibacterial', icon: 'fal fa-bacterium' },
          { name: 'Antifungal', icon: 'fal fa-disease' },
          { name: 'Antimalarial', icon: 'fal fa-virus' },
          { name: 'Antiparasitic', icon: 'fal fa-bug' },
          { name: 'Antiviral', icon: 'fal fa-virus' },
          { name: 'Antiparasitic', icon: 'fal fa-bug' },
          { name: 'Antimalarial', icon: 'fal fa-virus' },
          { name: 'Antiinflammatory', icon: 'fal fa-virus'},
        ],
        drugs: [],
        drugEditDialog: false,
        resistances: [],
        classes: [],
      }
    },
    watch: {
      options: {
        deep: true,
        handler () {
          this.fetchDrugs()
        },
      },
    },
    created () {
      this.axios.get('admin/resistances', { params: { count: 1000 } }).then((response) => {
        this.resistances = response.data.data
      })
      this.axios.get('admin/drugs/classes').then((response) => {
        this.classes = response.data
      })
    },
    methods: {
      fetchDrugs () {
        this.loading = true
        const promise = this.axios.get('admin/drugs', {
          params:
            {
              ...{
              page: this.options.page,
              count: this.options.itemsPerPage,
              sort: this.options.sortBy[0],
              order: this.options.sortDesc[0] ? 'desc' : 'asc',
              search: this.options.search,
            },
              ...this.$route.query,
            },
        })

        return promise.then((response) => {
          if (response.data.last_page < response.data.current_page) {
            this.options.page = response.data.last_page
            this.fetchDrugs()
            return
          }
          this.$set(this.options, 'page', response.data.current_page)
          this.$set(this.options, 'itemsPerPage', Number(response.data.per_page))
          this.totalItems = response.data.total
          this.drugs = response.data.data
        }).catch(error => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        }).finally(() => {
          this.loading = false
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
.inner-text {
  height: 100%;
  width: 100%;
  font-size: 3.75em;
  padding-top: 1.2em;

  span {
    max-width: 100px;
  }
}
</style>
